import React, { useState } from 'react';
import { CalendarIcon } from '@chakra-ui/icons';
import { Box, Icon, Text, useDisclosure } from '@chakra-ui/react';
import {
  FiUsers,
  FiFileText,
  FiXCircle,
  FiUser,
  FiMapPin,
} from 'react-icons/fi';
import { TimeIcon } from '@chakra-ui/icons';
import DashedContainer from 'src/components/Container/DashedContainer';
import { BsBox, BsAlarm, BsXSquare } from 'react-icons/bs';
import { FaTicketAlt } from 'react-icons/fa';
import { ReceiptModal } from 'src/components/Modal/ModalVariants/ReceiptModal';
import { EditPlayerModal } from 'src/components/Modal/ModalVariants/EditPlayersModal';
import { PophausModal } from 'src/components/Modal';
import { cancelBooking } from 'src/api/services/booking';
import { BookingPlayerResult, BookingResult } from 'src/api/interfaces/booking';
import { ApiResponse } from 'src/api';
import { KeyedMutator } from 'swr';
import { useNavigate } from 'react-router-dom';

export interface ActiveTicketProps {
  bookingID: string;
  date: string;
  time: string;
  name: string | undefined;
  room: string;
  unit: string | undefined;
  tickets: number;
  players?: BookingPlayerResult[] | undefined;
  mutate: KeyedMutator<ApiResponse<BookingResult[]>>;
  bookingTypeID: number;
}

export default function ActiveTicketCard({
  date,
  time,
  name,
  room,
  unit,
  tickets,
  bookingID,
  players,
  mutate,
  bookingTypeID,
}: ActiveTicketProps) {
  const navigate = useNavigate();
  const {
    onClose: onReceiptClose,
    onOpen: onReceiptOpen,
    isOpen: isReceiptOpen,
  } = useDisclosure();

  const {
    onClose: onPlayersClose,
    onOpen: onPlayersOpen,
    isOpen: isPlayersOpen,
  } = useDisclosure();

  const {
    onClose: onRescheduleClose,
    onOpen: onRescheduleOpen,
    isOpen: isRescheduleOpen,
  } = useDisclosure();

  const {
    onClose: onCancelClose,
    onOpen: onCancelOpen,
    isOpen: isCancelOpen,
  } = useDisclosure();

  const [loading, setLoading] = useState(false);

  const handleCancelBooking = async () => {
    try {
      setLoading(true);
      const req = await cancelBooking(bookingID);
      mutate();
    } catch (e) {
      console.error(e);
    } finally {
      onCancelClose();
      setLoading(false);
    }
  };

  const handleRescheduleBooking = () => {
    if (![1, 5].includes(bookingTypeID)) {
      return navigate(`/eventos/reagendar/${bookingID}`);
    }
    return navigate(`/jogador/reagendar/${bookingID}`);
  };

  return (
    <>
      <DashedContainer
        mb="10px"
        color="white"
        flexDir="row"
        justifyContent="space-around"
      >
        <Box display="flex" gap="5px" alignItems="center">
          <CalendarIcon color="green" />
          <Text>{date}</Text>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <TimeIcon color="green" />
          <Text>{time}</Text>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Icon as={FiUser} color="green" />
          <Text>{name}</Text>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Icon as={BsBox} color="green" />
          <Text>{room}</Text>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Icon as={FiMapPin} color="green" />
          <Text>{unit}</Text>
        </Box>
        <Box display="flex" gap="5px" alignItems="center">
          <Icon as={FaTicketAlt} color="green" />
          <Text>{tickets}</Text>
        </Box>

        <Box h="50px" border="2px dashed #B0CA0A" />

        <Box
          justifyContent="center"
          as="button"
          _hover={{ color: '#B0CA0A' }}
          onClick={onPlayersOpen}
        >
          <Icon as={FiUsers} />
          <Text fontSize="12px">Jogadores</Text>
        </Box>

        {/* <Box
          justifyContent="center"
          as="button"
          _hover={{ color: '#B0CA0A' }}
          onClick={onReceiptOpen}
        >
          <Icon as={FiFileText} />
          <Text fontSize="12px">Recibo</Text>
        </Box> */}

        <Box
          justifyContent="center"
          as="button"
          _hover={{ color: '#B0CA0A' }}
          onClick={onRescheduleOpen}
        >
          <Icon as={TimeIcon} />
          <Text fontSize="12px">Reagendar</Text>
        </Box>

        <Box
          justifyContent="center"
          as="button"
          _hover={{ color: '#B0CA0A' }}
          onClick={onCancelOpen}
        >
          <Icon as={FiXCircle} />
          <Text fontSize="12px">Cancelar</Text>
        </Box>
      </DashedContainer>
      <ReceiptModal
        bookingID={bookingID}
        isOpen={isReceiptOpen}
        onClose={onReceiptClose}
      />
      <EditPlayerModal
        bookingID={bookingID}
        isOpen={isPlayersOpen}
        onClose={onPlayersClose}
      />
      <PophausModal
        icon={BsAlarm}
        isOpen={isRescheduleOpen}
        onClose={onRescheduleClose}
        content="Deseja reagendar?"
        handleMainClick={handleRescheduleBooking}
        secondaryButtonText="voltar"
        handleSecondaryClick={onRescheduleClose}
      />
      <PophausModal
        icon={BsXSquare}
        isOpen={isCancelOpen}
        onClose={onCancelClose}
        content="Deseja cancelar?"
        handleMainClick={handleCancelBooking}
        secondaryButtonText="voltar"
        handleSecondaryClick={onCancelClose}
        loading={loading}
      />
    </>
  );
}
